"use client";

import React from "react";
import { CardAutocomplete } from "modules/common/components/autocomplete/CardAutocomplete";
import { AutocompleteMagicCard } from "modules/cards/model/card";
import debounce from "lodash/debounce";
import { useRecoilState, useResetRecoilState } from "recoil";
import { cardSearchReq } from "modules/cards/recoil/atoms";
import { useCardAutocomplete } from "modules/decks/hooks/useCardAutocomplete";
import { useRouter } from "next-nprogress-bar";
import { SxProps } from "@mui/material";
import { encodedCardName } from "utils/utils";

type Props = {
  styles?: SxProps;
  size?: "small" | "medium";
  onSearchComplete?: () => void; // New prop
};
const CardSearchAutocomplete = ({ styles, size, onSearchComplete }: Props) => {
  const router = useRouter();
  const [req, setReq] = useRecoilState(cardSearchReq);
  const resetReq = useResetRecoilState(cardSearchReq);
  const { query, loadMoreRef, opts } = useCardAutocomplete(req);

  const handleSelect = (e: any, card: AutocompleteMagicCard | null) => {
    if (card) {
      e.preventDefault();
      if (onSearchComplete) {
        onSearchComplete();
      }
      router.push(
        `/sets/${card.setCode}/${encodedCardName(card.name)}/${card.number}`
      );
      return;
    }
  };

  const handleInputChange = (event: any, next: any, reason: string) => {
    if (reason === "clear") {
      resetReq();
    }
  };

  const handleSearch = debounce(async (e: any) => {
    const val = e.target.value;
    if (val.length < 3) {
      return;
    }

    setReq({
      ...req,
      string: val,
    });
  }, 300);

  return (
    <CardAutocomplete
      clearOnBlur={true}
      rootSx={styles}
      size={size}
      onChange={handleSelect}
      opts={opts()}
      isLoading={query.isLoading}
      onInputChange={handleInputChange}
      loadMoreRef={loadMoreRef}
      handleSearch={handleSearch}
      isFetchingNextPage={query.isFetchingNextPage}
      autoFocus={false}
    />
  );
};

export default CardSearchAutocomplete;
