"use client";
import React, { ReactNode } from "react";
import { RecoilRoot } from "recoil";

interface Props {
  children: ReactNode;
  override?: boolean;
}

const RecoilProvider = ({ children, override = false }: Props) => {
  return <RecoilRoot override={override}>{children}</RecoilRoot>;
};

export default RecoilProvider;
