import { useFetchWrapper } from "modules/common/hooks/useFetchWrapper";
import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "modules/common/query";
import { API_ROUTES } from "utils/api-route.constants";

export const useVersion = () => {
  const fetch = useFetchWrapper();
  const q = useQuery({
    queryKey: [QUERIES.APP_VERSION],
    queryFn: getVersion,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  function getVersion() {
    return fetch.get(API_ROUTES.VERSION.GET, null);
  }

  return q;
};
