"use client";
import React, { SyntheticEvent } from "react";
import { AutocompleteMagicCard } from "modules/cards/model/card";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import AutocompleteListItem from "modules/common/components/autocomplete/AutocompleteListItem";
import { Clear } from "@mui/icons-material";

export interface CardAutocompleteProps {
  fullWidth?: boolean;
  handleSearch: any;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  loadMoreRef: any;
  onChange: (event: SyntheticEvent, item: AutocompleteMagicCard | null) => void;
  onInputChange: (event: any, value: string, reason: string) => void;
  opts: any[];
  size?: "small" | "medium";
  rootSx?: any;
  clearOnBlur?: boolean;
  autoFocus?: boolean;
  autoCompleteKey?: string;
}

export const CardAutocomplete = ({
  onChange,
  opts,
  isLoading,
  onInputChange,
  loadMoreRef,
  handleSearch,
  isFetchingNextPage,
  size = "medium",
  fullWidth = false,
  rootSx,
  clearOnBlur = true,
  autoCompleteKey = "",
  autoFocus = false,
}: CardAutocompleteProps) => {
  return (
    <Autocomplete
      sx={rootSx}
      id={"add-card-autocomplete"}
      size={size}
      fullWidth={fullWidth}
      noOptionsText={"No cards found"}
      loading={isLoading}
      onChange={onChange}
      onInputChange={onInputChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option: AutocompleteMagicCard) => option.name}
      options={opts}
      clearIcon={<Clear fontSize={"small"} />}
      clearOnEscape={true}
      clearOnBlur={clearOnBlur}
      key={autoCompleteKey}
      renderOption={(props, option, state) => {
        if (opts.length - 1 === state.index) {
          return (
            <Box key={state.index}>
              <AutocompleteListItem
                key={state.index}
                option={option}
                props={props}
              />
              <div ref={loadMoreRef} />
            </Box>
          );
        }
        return (
          <AutocompleteListItem
            key={state.index}
            option={option}
            props={props}
          />
        );
      }}
      renderInput={(params) => (
        <FormControl fullWidth>
          <TextField
            autoFocus={autoFocus}
            sx={rootSx}
            {...params}
            label={"Search for a card"}
            onChange={handleSearch}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetchingNextPage ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </FormControl>
      )}
    />
  );
};
