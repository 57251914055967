import { useQuery } from "@tanstack/react-query";
import { Notification } from "modules/chat/model/chat";
import { API_ROUTES } from "utils/api-route.constants";
import { useFetchWrapper } from "modules/common/hooks/useFetchWrapper";
import { useAuth } from "@clerk/nextjs";

export const useNotification = () => {
  const { userId } = useAuth();
  const fetch = useFetchWrapper();

  const { data: notifications = [] } = useQuery({
    queryKey: ["notifications", userId],
    queryFn: () => fetchNotifications(),
    enabled: !!userId,
    refetchInterval: 5000,
  });

  async function fetchNotifications(): Promise<Notification[]> {
    try {
      return await fetch
        .get(API_ROUTES.NOTIFICATIONS.BASE, null)
        .then((res: Notification[]) => res);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      throw error;
    }
  }

  const getLatestNotification = (
    notifications: Notification[]
  ): Notification | null => {
    if (notifications.length === 0) return null;

    notifications.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    return notifications[0];
  };

  return {
    notifications,
    latestNotification: getLatestNotification(notifications),
  };
};
