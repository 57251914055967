import createSvgIcon from "@mui/material/utils/createSvgIcon";
import React from "react";

export const CollectionIcon = createSvgIcon(
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M490.667,42.667h-85.333C393.551,42.667,384,52.218,384,64v21.333h-64V64c0-11.782-9.551-21.333-21.333-21.333h-85.333
			C201.551,42.667,192,52.218,192,64v21.333h-64V64c0-11.782-9.551-21.333-21.333-21.333H21.333C9.551,42.667,0,52.218,0,64v170.667
			V448c0,11.782,9.551,21.333,21.333,21.333h469.333c11.782,0,21.333-9.551,21.333-21.333V234.667V64
			C512,52.218,502.449,42.667,490.667,42.667z M426.667,85.333h42.667v128h-42.667V85.333z M320,213.333V192h64v21.333H320z
			 M128,192h64v21.333h-64V192z M234.667,256h42.667v21.333c0,11.791-9.542,21.333-21.333,21.333s-21.333-9.542-21.333-21.333V256z
			 M384,128v21.333h-64V128H384z M234.667,85.333h42.667v128h-42.667V85.333z M192,128v21.333h-64V128H192z M42.667,85.333h42.667
			v128H42.667V85.333z M469.333,426.667H42.667V256h64H192v21.333c0,35.355,28.645,64,64,64s64-28.645,64-64V256h85.333h64V426.667z
			"
    />
  </svg>,
  "Collection"
);
