"use client";
import {
  AutocompleteMagicCard,
  AutocompleteMagicCardsRequest,
  AutocompleteMagicCardsResponse,
} from "modules/cards/model/card";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useFetchWrapper } from "modules/common/hooks/useFetchWrapper";
import { QUERIES } from "modules/common/query";
import { API_ROUTES } from "utils/api-route.constants";
import { useEffect, useRef } from "react";

export const useCardAutocomplete = (req: AutocompleteMagicCardsRequest) => {
  const fetch = useFetchWrapper();
  const loadMoreRef = useRef(null);

  const infQuery = useInfiniteQuery<AutocompleteMagicCardsResponse, Error>({
    queryKey: [QUERIES.MAGIC_CARDS_AUTOCOMPLETE, req],
    queryFn: ({ pageParam }) =>
      autocomplete({ ...req, page: pageParam as number }),
    initialPageParam: 1,
    enabled: req.string.length >= 3,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => {
        if (entry.isIntersecting && !infQuery.isFetchingNextPage) {
          infQuery.fetchNextPage();
        }
      })
    );

    const el = loadMoreRef && loadMoreRef.current;
    if (!el) {
      return;
    }

    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  }, [loadMoreRef.current, infQuery.hasNextPage]);

  return {
    query: infQuery,
    loadMoreRef,
    opts,
  };

  function autocomplete(req: AutocompleteMagicCardsRequest) {
    if (req.string == "") {
      return Promise.resolve({} as AutocompleteMagicCardsResponse);
    }
    return fetch
      .post(API_ROUTES.MAGIC_CARDS.AUTOCOMPLETE, req)
      .then((res: AutocompleteMagicCardsResponse) => res);
  }

  function opts(): AutocompleteMagicCard[] {
    if (infQuery.data) {
      const res = infQuery.data.pages.flatMap((c) => c.items);
      if (!res) {
        return [];
      }
      return res;
    }
    return [];
  }
};
